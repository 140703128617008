import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/JYnYU2C95to">
    <SEO title="What's the Big Deal About the Sabbath? - The Big 10" />
  </Layout>
)

export default SermonPost
